import React from 'react'
import { graphql, Link } from 'gatsby'
import { Trans } from 'react-i18next'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import styled from 'styled-components'
import Layout from '../../layouts/base'

const Content = styled.div`
  max-width: 860px;
  margin: 0 auto;
  h1 {
    text-align: center;
    text-transform: uppercase;
  }
`

const Articles = styled.div`

`

const Article = styled.div`
  position: relative;
  box-shadow: 0 0 12px ${props => props.theme.text}66;
  border-bottom: 4px solid ${props => props.theme.accent};
  > :first-child {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: flex-start;
    h2 {
      background-color: ${props => props.theme.accent};
      color: #fff;
      padding-left: 20px;
      padding-right: 20px;
      padding-bottom: 4px;
      border-radius: 0px 7px 7px 0;
      display: inline-flex;
    }

    .serie {
      display: flex;
      align-self: flex-end;
      margin-bottom: 20px;
      border-radius: 7px 0 0 7px;
      justify-content: flex-end;
      padding-right: 20px;
      padding-left: 20px;
      padding-bottom: 3px;
      margin-top: auto;
      background-color: ${props => props.theme.accent};
      color: #fff;
    }
  }
`

interface IProps {
  data: {
    allMarkdownRemark: {
      edges: {
        node: {
          id: string
          frontmatter: {
            slug: string
            title: string
            serie: string
            date: string
            displayDate: string
            cover: {
              childImageSharp: any
            }
          }
        }
      }[]
    }
  }
}

const BlogPage = (props: IProps) => {
  const { data: { allMarkdownRemark: { edges } } } = props
  return <Layout>
    <Content>
      <h1><Trans>The Blog</Trans></h1>
      <Articles>
        {
          edges.map(({ node }) => {
            const cover = getImage(node.frontmatter.cover.childImageSharp)
            return <Link key={node.id} to={`/blog/articles/${node.frontmatter.slug}`}>
              <Article>
                <div>
                  <h2>{node.frontmatter.title}</h2>
                  <div className='serie'>{node.frontmatter.serie}</div>
                </div>
                { cover && <GatsbyImage image={cover} alt={node.frontmatter.title} /> }
              </Article>
            </Link>
          })
        }
      </Articles>
    </Content>
  </Layout>
}

export const query = graphql`
  query getArticles {
    allMarkdownRemark(filter:{fileAbsolutePath:{regex:"/data/blog/articles/"}}) {
      edges {
        node {
          id
          frontmatter {
            slug
            title
            serie
            displayDate
            cover {
              id
              childImageSharp {
                gatsbyImageData(placeholder:BLURRED, width: 500)
              }
            }
          }
          timeToRead
        }
      }
    }
  }
`;

export default BlogPage
